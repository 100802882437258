import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const Layout = ({ children, data, padding, mode, className, layers }) => {
  padding = !!padding ? padding : "10vh"
  mode = !!mode ? mode : "fixed"

  layers = !!layers ? layers : []
  const background = layers.map(layer => {
    const degree: string = !!layer.angle ? layer.angle : "180deg"
    const colors: string[] = layer.colors.map(color => {
      return `${color.color} ${color.position}`
    })

    return `linear-gradient(${degree}, ${colors.join(", ")})`
  })

  background.push(`url(${data.fluid.src})`)

  return (
    <>
      <div
        style={{
          padding: `${padding} 0`,
          background: background,
          backgroundAttachment: mode,
          backgroundSize: `cover`,
          backgroundPosition: `center center`,
        }}
        className={className}
      >
        {children}
      </div>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        images: allImageSharp {
          nodes {
            fluid(webpQuality: 100, fit: OUTSIDE) {
              src
              originalName
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.nodes.find(
        img => img.fluid.originalName === props.image
      )
      //   console.log(image.fluid.srcWebp)
      return <Layout image={image} data={image} {...props} />
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
